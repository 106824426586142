// Font.
$font: (
  primary: ("Varela Round",sans-serif),
  secondary: ("Lato",sans-serif),
  tertiary: ("Mukta",sans-serif)
);

// Palette.
$palette: (
  primary:           #1d1f24,
  secondary:         #0c2938,
  meta:              #064d70,
  border:            #0c7bb3,
  bg:                #ffffff,
  accent:            #0c7bb3,
  accent-alt:        #53baed,
  button-primary-bg: #cf7911,
  border-accent:     #cf7911,
  dark-accent:       #229ad6,
  accent-secondary:  #e89c3f
);

@import "imports/functions";

@import "imports/reset";
@import "imports/general";
@import "imports/icons";
@import "imports/structure";
@import "imports/header";
@import "imports/posts";
@import "imports/sections";
@import "imports/footer";
@import "imports/banner";
