/**
 * Site Footer
 * ----------------------------------------------------------------------------
 */
#colophon {
  background: _palette(secondary);
  color: _palette(dark-accent);

  a {
    color: _palette(accent-alt);

    &:hover,
    &:focus {
      border-color: currentColor;
      color: _palette(border);
    }
  }
}

.footer-top {
  padding-bottom: 2.5rem;
  padding-top: 3.33333rem;
}

.footer-widgets,
.footer-nav-inside {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -0.83333rem;
  margin-right: -0.83333rem;
}

.widget {
  box-sizing: border-box;
  -webkit-flex: 0 0 33.333%;
  -ms-flex: 0 0 33.333%;
  flex: 0 0 33.333%;
  font-size: 0.88889rem;
  line-height: 1.5;
  padding-left: 0.83333rem;
  padding-right: 0.83333rem;
}

.widget-title {
  color: _palette(dark-accent);
  font-size: 1rem;
  margin: 0 0 1.33333rem;
}

.footer-branding {
  a {
    border: 0;
  }

  .site-title {
    font-size: 1.33333rem;

    &:last-child {
      margin-bottom: 2.5rem;
    }
  }

  .site-description {
    margin: 0.5rem 0 2.5rem;
  }
}

.secondary-nav,
.social-nav {
  box-sizing: border-box;
  -webkit-flex: 1 1 150px;
  -ms-flex: 1 1 150px;
  flex: 1 1 150px;
  padding-left: 0.83333rem;
  padding-right: 0.83333rem;

  ul {
    list-style: none;
    margin-bottom: 2.5rem;
    padding: 0;
  }

  li {
    margin: 0 0 0.75em;
  }

  a {
    border: 0;
  }
}

.site-info {
  border-top: 1px solid _palette(primary);
  font-size: 14px;
  line-height: 1.5;
  padding-bottom: 1.66667rem;
  padding-top: 1.66667rem;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .footer-branding {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .footer-navigation,
  .footer-subscribe {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 600px) {
  .widget {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-bottom: 1rem;
  }
}
